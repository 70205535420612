import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import {ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/shared/translation.service';
import { PagesService} from '../../pages/pages.service';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

  policyData:any ;

  constructor(private spinner: NgxSpinnerService, 
    public translate: TranslationService,
    private pagesService: PagesService) { }

  ngOnInit(): void {
    this.spinner.show();
    this.pagesService.getAbout().subscribe(
      (response: any) => {
        console.log(response);
        this.policyData = response.data[0];
        console.log("policy dt=======> ",this.policyData);
        this.spinner.hide();
      },
      (error) => {
        console.log(error);
      }
    )
  }

}
