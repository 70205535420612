<div class="container my-5">
  <div class="row">
    <div [ngClass]="{'header': translate.is_arabic, 'headerEn': !translate.is_arabic}">
      <p>{{translate.data.TermsCond.TermsCond}}</p>
    </div>
    <div [ngClass]="{'body': translate.is_arabic, 'bodyyEn': !translate.is_arabic}">
      <div>
        <h4>{{translate.data.TermsCond.terms}}</h4>
        <p [innerHTML]="policyData ? policyData[translate.data.requestdData.genTerms] : '' "></p>
      </div>
      <div>
        <h4>{{translate.data.TermsCond.Conditions}}</h4>
        <p [innerHTML]="policyData ? policyData[translate.data.requestdData.genPrivacy]: ' ' "></p>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
